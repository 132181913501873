/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-07 15:55:14
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-07 16:32:11
 */
export const columns = [
{
    title: '功能类型名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
